.aboutContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutImage{
    width: 450px;
}

.img-class{
    justify-content: center;
    align-items: center;
    display: flex;
}
