.serviceContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #174073;
    /* background: aliceblue;     */
}

.serviceArea{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

.serviceCard{
    width: 18rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 7%;
}

.sectionSubHeader{
    color: #174073;
    font-family: 'BalsamiqSans-Bold';
}

.sectionText{
    color: black;
    font-family: 'BalsamiqSans-Regular';
}

.service-img{
    max-height: 25vh;
    min-height: 25vh;
}