.galleryDiv{
    min-width: 92%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: #174073;
    color: white;
    padding: 2%;
    /* height: 100vh; */
    width: 100%;
    text-align: center;
}
.gallery-img{
    min-width: 300px;
    max-width: 300px;
    min-height: 220px;
    max-height: 220px;
    margin: 3%;
    /* margin-left: 5%; */
    cursor: pointer;
}

.back-btn{
    color: white;
    margin-bottom: 1%;
    font-size: 20px;
    text-decoration: underline;
    /* border: 1px solid white; */
}

.back-btn:hover{
    color: cyan;
    text-decoration: none;
}

.galleryContainer{
    background-color: #174073;
}

#gallerylItem{
    border: 1px solid white;
    min-width: 320px;
    max-width: 320px;
    min-height: 580px;
    max-height: 580px;
    margin-bottom:2%;
    padding-bottom:1%
    
}

.galleryItemContainer{
    justify-content: center;
}

.galleryProductSpecsDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 6%;
}

.galleryProductTitle{
    color: darkorange;
}

.galleryProductSpecsTitle{
    font-size: 25px;
    color: #10ef03;
}

.galleryProductSpecsDetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
}

.categorySelect{
    margin: 1%;
}