#carouselItem{
    /* background-image: linear-gradient(to right bottom, rgba(11, 101, 161, 0.8), rgba(4, 37, 59, 0.726)), url(../assets/images/factory1.jpg); */
    background-image: linear-gradient(to right bottom, rgba(4, 45, 73, 0.8), rgba(6, 30, 46, 0.726)), url(../assets/images/banner.jpg);
    /* background-image: linear-gradient(to right bottom, rgba(7, 76, 122, 0.8), rgba(6, 30, 46, 0.726)), url(../assets/images/factory1.jpg); */
    
    height: 100vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.carouselContent{
    margin-left: 12%;
    margin-top: 22%;
}
.carouselContentCaption{
    margin-top: 1%;
    margin-left: 12%;
}

#title{
    font-size: 55px;
    font-weight: bold;
    color: #00AEEF;
}

.caption1{
    font-size: 35px;
    font-weight: bold;
    color: rgb(119, 158, 230);
}


.caption2{
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
    color: rgb(211, 218, 233);
}