.contactContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #174073;
    color: white;
}
.title{
    font-weight: bolder;
    font-size: 50px;
}

.caption{
    font-weight: bolder;
    font-style: italic;
}

.address{
    font-size: 22px;
}

.email{
    font-size: 25px;
    margin: 0;
}

.fa-icon{
    font-size: 30px;
}
.fa-icon2{
    font-size: 30px;
    margin-right: 4%;
    margin-top: 1%;
    margin-bottom: 2%;
    cursor: pointer;
    border: 1px solid white;
    height: 40px;
    display: inline-grid;
    width: 40px;
    border-radius: 55%;
    justify-content: center;
    align-items: center;
}