@font-face{
  font-family: 'Anton-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Anton-Regular.ttf);
}

@font-face{
  font-family: 'BalsamiqSans-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/BalsamiqSans-Regular.ttf);
}

@font-face{
  font-family: 'BalsamiqSans-Bold';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/BalsamiqSans-Bold.ttf);
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: aliceblue;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sectionHeader{
  margin: 1.5%;
  color: #00AEEF;
  font-family: 'Anton-Regular';
  font-size: 2rem;
  display: contents;
}

.footer{
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.socialLinks{
  text-decoration: none;
  color: white;
}

.socialLinks:hover{
  color: #00AEEF;
  text-decoration: none;
}

/* SCROLLBARS */

/* width */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #00AEEF; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #174073; 
  border-radius: 10px;
  border: 1px solid white;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00AEEF; 
}

/* SCROLLBARS */

.sectionHeaderDiv{
  margin: 2%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .aboutImage{
    display: none;
  }
  .sectionHeaderDiv{
    margin-top: 5%;
  }
  .title{
    font-size: 30px !important;
  }
  .nav-item:hover{
    width: 110px;
    text-align: center;
   }
}

@media only screen and (max-width: 400px) {
  #gallerylItem{
    min-width: 100% !important; 
    min-height: 588px !important;
    max-height: 588px !important;
   }
   .gallery-img{
     height: 40% !important;
     min-width: 250px !important;
     max-width: 250px !important;
   }
   .galleryProductSpecsDetails {
      margin-left: -15px !important;
  }
}
