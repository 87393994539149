.navbar-collapse{
    justify-content: flex-end !important;
}

.nav-li{
    margin-right: 20px;
}


li a {
    border-radius: 10px;
   
    --slideTextColor: #272838;
    --initialBgColor: #174073;
    --slideBgColor: #FFF;


    background-image: linear-gradient(90deg,
    var(--initialBgColor) 0%,
    var(--initialBgColor) 50%,
    var(--slideBgColor) 50%,
    var(--slideBgColor) 100%);
    background-size: 200%;
    transition: background-position .2s cubic-bezier(.47, .1, 1, .63),
      color .2s linear;
    transition-delay: 0.0s, 0.1s;
  }

  li a:hover {
    color: #174073 !important; 
    cursor: pointer;
    background-position: -100% 100%;
    border-radius: 10px;
  }
  .nav-item:hover{
    text-align: center;
  }

/* li a:hover{
    background-color: #DCF2FD;
    border-radius: 10px;
    list-style: none;
    color: #174073 !important; */
/* } */

#logo{
    width: 60px;
    height: 55px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
}

.navbar-color{
    background-color: #174073;
}