.productContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    color: white;
}

.productArea{
    display: flex;
    justify-content: space-evenly;
    /* width: 100%; */
    flex-wrap: wrap;
}
/* 
.productName{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: 'BalsamiqSans-Bold';
    color: rgb(109, 62, 236);
}
.productCard{
  height: 55vh;
  cursor: pointer;
  flex-wrap: wrap;
}

.productCardText{
    border: 1px solid;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 15vh;
}

.productBody{
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.productFeatureTab{
    background-color: #1554A6;
    padding:1%;
    color: white;
    font-weight: bold;
    width: 100%;
    height: 5vh;
    text-align: center;
}

.productFeatureText{
    margin: 2%;
}

.productImage{
    height: 37vh;
} */


/* */
.card-container, .front, .back {
	width: 20rem;
	height: 20rem;
}
.card-container {
	margin: 2rem;
}
.front, .back {
	/* padding: 2rem; */
	border-radius: 4px;
	text-transform: uppercase;
	text-align: center;
}

.front{
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #174073;
}

.back{
  border: 2px solid #174073;
  border: 1px solid white;
  /* margin-top: 40px; */
  background-color: #174073;
}

.card-container {
  position: relative;
  cursor: pointer;
}
.front, .back {
	position: absolute;
	top: 0;
	left: 0;
	backface-visibility: hidden;
	overflow: hidden;
	transition: transform .8s ease;
}
.back {
	transform: rotateY(180deg);
}
.card-container:hover .front {
	transform: rotateY(-180deg);
}
.card-container:hover .back {
	transform: rotateY(0deg);
}

.card-container {
	perspective: 75rem;
}


.productImage{
  /* height: 40vh; */
  width: 100%;
} 

.applicationDiv{
  /* margin-top: 3%; */
  /* margin-bottom: 3%; */
  /* background-color: red; */
  height: 100%;
  /*display: flex;
  flex: 1;
  flex-direction: column; */
}

.view-more-btn{
  /* color: white; */
  margin-bottom: 1%;
  font-size: 20px;
  text-decoration: underline;
  /* border: 1px solid white; */
}

.view-more-btn:hover{
  /* color: cyan; */
  color: #174073;
  text-decoration: none;
}